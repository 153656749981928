import {WorkflowStatusCode} from "../common/enum/workflow-status-code.enum";
import {AssignedStatus} from "./assigned-status.enum";
import {AccommodationInstructions} from "../model/types/accommodation-instructions";
import {DeliveryInstruction} from "./delivery-instruction.enum";
import {LectureBeforeOrAfter} from "./lecture-before-or-after.enum";
import {AssessmentTimingInd} from "./assessment-timing-ind.enum";
import {AssessmentCompletionInd} from "../common/types/assessment-completion-ind.enum";
import moment from "moment";
import {OnlineVsInPerson} from "./online-vs-in-person.enum";

export class CisSubmissionsUtils {

  // -- Used in "Filter by Assigned Status" --
  static assignedStatusVals: AssignedStatus [] = [
    AssignedStatus.ASSIGNED,
    AssignedStatus.NOT_ASSIGNED
  ];

  static assignedStatusMap: Map<string, string> = new Map([
    [AssignedStatus.ASSIGNED, 'Assigned'],
    [AssignedStatus.NOT_ASSIGNED, 'Not Assigned']
  ]);

  // -- Used in "Filter by Review Status" --
  // static reviewStatusCodeVals: ReviewStatusCode[] = [
  //   ReviewStatusCode.REVIEWED,
  //   ReviewStatusCode.REVIEWED_INSTRUCTOR_UPDATED,
  //   ReviewStatusCode.PENDING_CONFIRMATION,
  //   ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE,
  //   ReviewStatusCode.PENDING_CONFIRMATION_INSTRUCTOR_UPDATED,
  //   ReviewStatusCode.NOT_REVIEWED
  // ];

  // static reviewStatusCodeMap: Map<string, string> = new Map([
  //   [ReviewStatusCode.REVIEWED, 'Reviewed'],
  //   [ReviewStatusCode.REVIEWED_INSTRUCTOR_UPDATED, 'Reviewed - Instructor Updated'],
  //   [ReviewStatusCode.PENDING_CONFIRMATION, 'Pending Confirmation'],
  //   [ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE, 'Pending Confirmation - Awaiting File'],
  //   [ReviewStatusCode.PENDING_CONFIRMATION_INSTRUCTOR_UPDATED, 'Pending Confirmation - Instructor Updated'],
  //   [ReviewStatusCode.NOT_REVIEWED, 'Not Reviewed']
  // ])

  static onlineVsInPersonVals: OnlineVsInPerson[] = [
    OnlineVsInPerson.ONLINE_REMOTE,
    OnlineVsInPerson.ONLINE_IN_PERSON,
    OnlineVsInPerson.ONLINE_DOWNLOAD_AND_UPLOAD,
    OnlineVsInPerson.IN_PERSON,
    OnlineVsInPerson.IN_PERSON_TAKE_HOME
  ];

  static onlineVsInPersonMap: Map<string, string> = new Map([
    [OnlineVsInPerson.ONLINE_REMOTE, 'Online - Remote'],
    [OnlineVsInPerson.ONLINE_IN_PERSON, 'Online - In-person'],
    [OnlineVsInPerson.ONLINE_DOWNLOAD_AND_UPLOAD, 'Online - Download & Upload'],
    [OnlineVsInPerson.IN_PERSON, 'In-person'],
    [OnlineVsInPerson.IN_PERSON_TAKE_HOME, 'In-person - Take home']
  ])

  // -- Used in "Filter by CIS Submission Status" --
  static cisSubmissionStatusVals: WorkflowStatusCode[] = [
    WorkflowStatusCode.NEED_FILES,
    WorkflowStatusCode.SUBMITTED_TO_ATS,
    // WorkflowStatusCode.NEEDS_SUBMITTING
  ];

  static cisSubmissionStatusMap: Map<WorkflowStatusCode, string> = new Map([
    [WorkflowStatusCode.NEED_FILES, 'Step 3'],
    [WorkflowStatusCode.SUBMITTED_TO_ATS, 'Step 5'],
    // [WorkflowStatusCode.NEEDS_SUBMITTING, 'Not Submitted']
  ]);

  static deliveryInstructionMap: Map<string, string> = new Map([
    [DeliveryInstruction.DELIVERY_TO_UNIT.toString(), 'Delivered to department/ academic unit'],
    [DeliveryInstruction.HOLD_AT_ATS.toString(), 'Held at ATS for pick-up'],
    [DeliveryInstruction.NA.toString(), 'NA - no physical test or materials to be submitted/delivered']
  ]);

  static lectureBeforeOrAfterMap: Map<string, string> = new Map([
    [LectureBeforeOrAfter.BEFORE_ASSESSMENT.toString(), 'Before'],
    [LectureBeforeOrAfter.AFTER_ASSESSMENT.toString(), 'After'],
    [LectureBeforeOrAfter.NO_LECTURE.toString(), 'None']
  ]);

  static assessmentTimingIndMap: Map<string, string> = new Map([
    [AssessmentTimingInd.STRICT.toString(), 'Strict'],
    [AssessmentTimingInd.FIXED.toString(), 'Fixed'],
    [AssessmentTimingInd.FLEXIBLE.toString(), 'Flexible']
  ]);

  static assessmentCompletionIndMap: Map<string, string> = new Map([
    [AssessmentCompletionInd.IN_PERSON.toString(), 'In Person'],
    [AssessmentCompletionInd.TAKE_HOME.toString(), 'Take Home'],
    [AssessmentCompletionInd.ONLINE.toString(), 'Online']
  ]);

  // -- Methods --
  public static formatAccommodationInstructions(courseObj: AccommodationInstructions) {
    return `${courseObj.courseCode?.trim()}${courseObj.sectionCode?.trim()}${courseObj.teachMethod?.trim()}${courseObj.sectionNumber?.trim()}`;
  }

  public static formatCourse(courseObj: AccommodationInstructions) {
    return `${courseObj.courseCode?.trim()} ${courseObj.sectionCode?.trim()} ${courseObj.teachMethod?.trim()}${courseObj.sectionNumber?.trim()}`;
  }

  public static formatClassWritingDate(date: Date) {
    return !date ? '' : date.toString();
  }

  public static formatClassWritingTime(date: Date) {
    return !date ? '' :  moment(date).format('HH:mm');
  }

  public static formatDateTime(date: Date) {
    return !date ? '' :  moment(date).format('yyyy-MM-DD HH:mm');
  }

  // /** Based on the submission status code, determines the user-friendly status to be shown on dashboard. */
  // public static determineCisSubmissionStatus(submissionStatus: string): string {
  //   if (!submissionStatus) {
  //     return 'N/A';
  //   }
  //
  //   let status = '';
  //   if (submissionStatus === WorkflowStatusCode.NEED_FILES) {
  //     status = 'Step 3';
  //   } else if (submissionStatus === WorkflowStatusCode.SUBMITTED_TO_ATS) {
  //     status = 'Step 5';
  //   } else {
  //     status = 'N/A';
  //   }
  //
  //   return status;
  // }

  /** Based on the submission timing type indicator, determines the user-friendly timing type to be shown on dashboard. */
  public static determineAssessmentTimingType(timingInd: string): string {
    if (!timingInd) {
      return 'N/A';
    }

    let timingType = '';
    if (timingInd === AssessmentTimingInd.STRICT) {
      timingType = 'Strict';

    } else if (timingInd === AssessmentTimingInd.FIXED) {
      timingType = 'Fixed';

    } else if (timingInd === AssessmentTimingInd.FLEXIBLE) {
      timingType = 'Flexible';
    }

    return timingType;
  }

  public static printPageSize(pageSize: string): string {
    return pageSize === 'LEGAL' ? 'Legal (8.5" x 14")' : pageSize === 'LETTER' ? 'Letter (8.5" x 11")' : 'N/A';
  }

  // Should be printed colour or black-and-white
  // Note: apparently in CIS we set only COLOR or BLACK_AND_WHITE
  public static printColour(colourSettings: string) {
    return colourSettings === 'COLOR' ? 'Full Colour' : colourSettings === 'BLACK_AND_WHITE' ? 'Black & White' : 'N/A';
  }

  // On which side should the page be printed
  public static printSide(twoSided: boolean) {
    return twoSided === true ? 'Print 2-sided' : twoSided === false ? 'Print 1-sided' : 'N/A';
  }


}
