
<div class="cis-submisison-gen-info">
  <div>
    <!-- Review Status -->
    <div><span class="fw-bold">Review Status:</span>
      {{CommonUtils.reviewStatusCodeMap.get(cisSubmission?._reviewStatus || this.ReviewStatusCode.NOT_REVIEWED)}}</div>
    <!-- Submission Status -->
    <div><span class="fw-bold">Submission Status:</span>
      {{this.CommonUtils.determineCisSubmissionStatus(cisSubmission?.workflowStatus?.statusCode)}}</div>
  </div>


  <!-- Course, Section -->
  <div class="margin-top-2-rem"><span class="fw-bold">Course, Section:</span>
    {{Utils.formatCourse(cisSubmission)}}</div>
  <!-- Assessment Type -->
  <div><span class="fw-bold">Assessment Type:</span>
    {{cisSubmission.testSubtype}} {{cisSubmission.testType}}</div>
  <!-- Number of Bookings -->
  <div><span class="fw-bold">Number of Bookings:</span>
    {{cisSubmission.numberOfBookings}}</div>
  <!-- Test Definition ID -->
  <div><span class="fw-bold">Test Definition ID:</span>
    {{cisSubmission.atsTestDefinitionId}}</div>
  <!-- Accommodation Instruction ID -->
  <div><span class="fw-bold">Accommodation Instruction ID:</span>
    {{cisSubmission.accommodationInstructionsId}}</div>
  <!-- Clockwork Exam ID -->
  <div><span class="fw-bold">Clockwork Exam ID:</span>
    {{cisSubmission.clockworkTestDefinitionId}}</div>
</div>
