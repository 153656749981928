import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {LogService} from "../../common/services/log-service.service";
import {MatTableDataSource} from "@angular/material/table";
import {AccommodationInstructions} from "../../model/types/accommodation-instructions";
import {CisSubmissionsUtils} from "../cis-submissions-utils";
import {CommonUtils} from "../../common/utils/common-utils";
import {AssignedStatus} from "../assigned-status.enum";
import {ReviewStatusCode} from "../../common/enum/review-status-code.enum";

@Component({
  selector: 'app-cis-submissions-filter',
  templateUrl: './cis-submissions-filter.component.html',
  styleUrls: ['./cis-submissions-filter.component.scss']
})
export class CisSubmissionsFilterComponent implements OnInit, OnChanges {

  // Reference to the datasource of the Angular Material Table
  @Input() dataSource: MatTableDataSource<AccommodationInstructions>;

  // Holds the typed or selected value from the drop-down.
  courseFilter: string;
  atsTestDefIdFilter: string;
  utorIdFilter: string;
  assignedStatusFilter: string;
  reviewStatusFilter: string;
  cisSubmissionStatusFilter: string;
  numberOfBookingsFilter: string;
  onlineVsInPersonFilter: string;

  // Filters
  courseCtrl = new FormControl('');
  atsTestDefIdCtrl = new FormControl('');
  utorIdCtrl = new FormControl('');
  assignedStatusCtrl = new FormControl('');
  reviewStatusCtrl = new FormControl('');
  cisSubmissionStatusCtrl = new FormControl('');
  numberOfBookingsCtrl = new FormControl('');
  onlineVsInPersonCtrl = new FormControl('');

  constructor(private logger: LogService) {}

  ngOnInit(): void {

    this.courseCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.courseFilter = val;
      this.dataSource.filter = 'COURSE_CTRL_FILTER: ' + val;
    });

    this.atsTestDefIdCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.atsTestDefIdFilter = val;
      this.dataSource.filter = 'TEST_DEF_ID_CTRL_FILTER: ' + val;
    });

    this.utorIdCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.utorIdFilter = val;
      this.dataSource.filter = 'UTORID_CTRL_FILTER: ' + val;
    });

    this.assignedStatusCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.assignedStatusFilter = val;
      this.dataSource.filter = 'ASSIGNED_STATUS_CTRL_FILTER: ' + val;
    });

    this.reviewStatusCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.reviewStatusFilter = val;
      this.dataSource.filter = 'REVIEW_STATUS_CTRL_FILTER: ' + val;
    });

    this.cisSubmissionStatusCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.cisSubmissionStatusFilter = val;
      this.dataSource.filter = 'CIS_SUBMISSION_STATUS_CTRL_FILTER: ' + val;
    });

    this.numberOfBookingsCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.numberOfBookingsFilter = val;
      this.dataSource.filter = 'NUMBER_OF_BOOKINGS_CTRL_FILTER: ' + val;
    });

    this.onlineVsInPersonCtrl.valueChanges.subscribe( (val: string) => {
      this.onlineVsInPersonFilter = val;
      this.dataSource.filter = 'ONLINE_VS_IN_PERSON_CTRL_FILTER' + val;
    })

  }

  ngOnChanges(changes: SimpleChanges): void {

    // Once data source input is received from the parent, do the filtering.
    if (this.dataSource != null && changes['dataSource'].currentValue != null) {

      this.dataSource.filterPredicate = (data: AccommodationInstructions, filter: string) => {

        this.logger.debug(`=> filter: ${filter}`, `${this.constructor.name}`);

        return this._filterByCourse(data, this.courseFilter)
          && this._filterByAtsTestDefId(data, this.atsTestDefIdFilter)
          && this._filterByUtorId(data, this.utorIdFilter)
          && this._filterByAssignedStatus(data, this.assignedStatusFilter)
          && this._filterByReviewStatus(data, this.reviewStatusFilter)
          && this._filterByCisSubmissionStatus(data, this.cisSubmissionStatusFilter)
          && this._filterByNumberOfBookings(data, this.numberOfBookingsFilter)
          && this._filterByOnLineVsInPerson(data, this.onlineVsInPersonFilter);
      }
    }
  }

  _filterByCourse(data: AccommodationInstructions, filter: string): boolean {
    return !filter ? true : this.CommonUtils.checkMatch(this.Utils.formatAccommodationInstructions(data), filter);
  }

  _filterByAtsTestDefId(data: AccommodationInstructions, filter: string): boolean {
    return !filter ? true : this.CommonUtils.checkMatch(data.atsTestDefinitionId?.toString(), filter);
  }

  _filterByUtorId(data: AccommodationInstructions, filter: string): boolean {
    return !filter ? true : this.CommonUtils.checkMatch(data._reviewer, filter);
  }

  _filterByAssignedStatus(data: AccommodationInstructions, filter: string): boolean {
    // return !filter ? true : filter === this.AssignedStatus.ASSIGNED ? data._reviewer !== null : !data._reviewer;

    if (!filter) {
      return true;

    } else if (filter === this.AssignedStatus.ASSIGNED) {
      return !!data._reviewer;

    } else if (filter === this.AssignedStatus.NOT_ASSIGNED) {
      return !data._reviewer;
    }

  }

  _filterByReviewStatus(data: AccommodationInstructions, filter: string): boolean {

    if (!filter) {
      return true;

    } else if (filter === ReviewStatusCode.NOT_REVIEWED) {
      return data._reviewStatus === ReviewStatusCode.NOT_REVIEWED || !data._reviewStatus;

    } else if (filter === ReviewStatusCode.REVIEWED) {
      return data._reviewStatus === ReviewStatusCode.REVIEWED;

    } else if (filter === ReviewStatusCode.REVIEWED_INSTRUCTOR_UPDATED) {
      return data._reviewStatus === ReviewStatusCode.REVIEWED_INSTRUCTOR_UPDATED;

    } else if (filter === ReviewStatusCode.PENDING_CONFIRMATION) {
      return data._reviewStatus === ReviewStatusCode.PENDING_CONFIRMATION;

    } else if (filter === ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE) {
      return data._reviewStatus === ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE;

    } else if (filter === ReviewStatusCode.PENDING_CONFIRMATION_INSTRUCTOR_UPDATED) {
      return data._reviewStatus === ReviewStatusCode.PENDING_CONFIRMATION_INSTRUCTOR_UPDATED;

    }
  }

  _filterByCisSubmissionStatus(data: AccommodationInstructions, filter: string): boolean {
    return (!filter) ? true : data.workflowStatus.statusCode === filter;
  }

  _filterByNumberOfBookings(data: AccommodationInstructions, filter: string): boolean {
    return !filter ? true : filter === '0' ? data.numberOfBookings === 0 : data.numberOfBookings === 1;
  }

  _filterByOnLineVsInPerson(data: AccommodationInstructions, filter: string): boolean {
    return !filter ? true : data.__onlineVsInPerson === filter;
  }

  resetFilter() {
    this.courseCtrl.setValue('');
    this.atsTestDefIdCtrl.setValue('');
    this.assignedStatusCtrl.setValue('');
    this.reviewStatusCtrl.setValue('');
    this.cisSubmissionStatusCtrl.setValue('');
    this.numberOfBookingsCtrl.setValue('');
    this.onlineVsInPersonCtrl.setValue('');

    // This will trigger the filtering refreshing.
    this.dataSource.filter = '';
  }

  protected  readonly Utils = CisSubmissionsUtils;
  protected  readonly CommonUtils = CommonUtils;
  protected readonly AssignedStatus = AssignedStatus;

}
