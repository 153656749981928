import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TestDefinition} from "../../model/types/test-definition";
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, Validators} from "@angular/forms";
import {TestDefinitions} from "../../model/types/test-definitions";
import {SelectionModel} from "@angular/cdk/collections";
import {Router} from "@angular/router";
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {MatSort} from "@angular/material/sort";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {EmailReminder} from "../email-reminder.enum";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import moment from "moment/moment";
import {DateFormatConstants} from "../../common/types/dateFormatConstants";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {EmailLogModalComponent} from "../email-log-modal/email-log-modal.component";
import {NotificationLog} from "../notification-log";
import {Observable} from "rxjs";
import {CommonUtils} from "../../common/utils/common-utils";

@Component({
  selector: 'app-email-dashboard',
  templateUrl: './email-dashboard.component.html',
  styleUrls: ['./email-dashboard.component.scss']
})
export class EmailDashboardComponent implements OnInit, OnDestroy {

  @Input()
  dataSource: MatTableDataSource<TestDefinition>;

  displayColumns: string[] = [];

  // All the columns to be displayed in the table
  defaultColumns: string[] = ['selectAll', 'courseSection', 'type', 'testDefinitionId', 'startTime',
                                  'numberOfBookings', 'cisSubmissionStatus', 'instructorEmailStatus', ' '];

  // For assessment past dates, the 'selectAll' and ' ' Send Email buttons columns are not displayed.
  pastDateColumns: string[] = ['courseSection', 'type', 'testDefinitionId', 'startTime',
                                  'numberOfBookings', 'cisSubmissionStatus', 'instructorEmailStatus'];

  searchByAssessmentDateCtrl = new FormControl('', Validators.required);

  @Input()
  testDefinitions: TestDefinitions;

  // Used by <mat-checkbox> in material table.
  selection: SelectionModel<TestDefinition> = new SelectionModel<TestDefinition>(true, []);

  // Get today's date as a string; used to compare with the assessment date for showing more or less table columns.
  todayDate = moment().format(DateFormatConstants.YYYYMMDD);

  constructor(private router: Router,
              private modalService: NgbModal,
              private model: ModelService,
              private logger: LogService) {}

  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {

    this.displayColumns = this.defaultColumns;

    // Get data from browser session, if stored.
    let assessmentDate = this._getAssessmentDate();

    // Default the assessment date to the next calendar date, if it is not stored in browser session.
    if (!assessmentDate) {
      assessmentDate = moment().add(1, 'days').format(DateFormatConstants.YYYYMMDD);
    }

    // Set 'Search by Assessment Date' calendar date to next day
    this.searchByAssessmentDateCtrl.setValue(assessmentDate);

    this._findTestDefinitions(assessmentDate);
  }

  ngOnDestroy(): void {
    this._saveAssessmentDate();
  }


  goToManageCourseContacts() {
    this.router.navigateByUrl('instructor-emailing/manage-course-contacts');
  }

  searchByAssessmentDate() {
    const assessmentDate = this.searchByAssessmentDateCtrl.value;

    if (!assessmentDate) return;

    // If the assessment date is past date, do not show the checkboxes and send email table columns
    if (this.searchByAssessmentDateCtrl.value < this.todayDate) {
      // Removed select checkbox and ' ' (Send Email) columns
      this.displayColumns = this.pastDateColumns;

    } else {
      // Added back the select checkbox and ' ' (Send Email) columns
      this.displayColumns = this.defaultColumns;
    }

    this._findTestDefinitions(assessmentDate)
  }

  viewActivityLog(testDef: TestDefinition) {
    let notificationLog$: Observable<NotificationLog[]> = this.model.getNotificationLog(testDef.testDefinitionId);

    let modalRef = this._openModal();
    modalRef.componentInstance.testDefinition = testDef;
    modalRef.componentInstance.notificationLog$ = notificationLog$;
  }

  // Stores assessment date in browser's 'sessionStorage'
  _saveAssessmentDate() {
    sessionStorage.setItem("assessmentDate", this.searchByAssessmentDateCtrl.value);
  }

  // Retrieves assessment date from the browser's 'sessionStorage'
  _getAssessmentDate(): string {
    return sessionStorage.getItem("assessmentDate");
  }

  _findTestDefinitions(assessmentDate: string) {
    this.logger.debug(`=> Finding test definitions for assessment date: ${assessmentDate}`, `${this.constructor.name}`);

    this.model.getInstrEmailingTestDefs(assessmentDate).subscribe( (val: TestDefinitions) => {
      this.testDefinitions = val;
      this.dataSource = new MatTableDataSource<TestDefinition>(val.testDefinitions);

      // register sorting
      this._registerSortingCallback();

      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  _registerSortingCallback() {
    this.dataSource.sortingDataAccessor = (data: TestDefinition, sortHeaderId: string) => {

      if (sortHeaderId === 'courseSection') {
        return this.Utils.formatTestDefinitionForSorting(data);

      } else if (sortHeaderId === 'type') {
        return data.testSubType + ' ' + data.testType;

      } else if (sortHeaderId === 'testDefinitionId') {
        return data.testDefinitionId.toString();

      } else if (sortHeaderId === 'startDate') {
        return data.classStartDate.toString();

      } else if (sortHeaderId === 'startTime') {
        return data.classStartTime.toString();

      } else if (sortHeaderId === 'numberOfBookings') {
        return data.numberOfApprovedBookings.toString();

      } else if (sortHeaderId === 'cisSubmissionStatus') {
        return data.cisSubmissionStep;
      }
    }
  }

  emailReminder(emailReminder: string, testDefId: number) {
    this.logger.debug(`=> Email Reminder: ${emailReminder}, testDefId: ${testDefId}`, `${this.constructor.name}`);

    this.router.navigate(['/instructor-emailing/send-email'],
      { queryParams: { 'emailType': emailReminder, 'testDefId': testDefId}});
  }

  bulkEmailReminder(emailReminder: string) {

    const selectedTestDefs = this.selection.selected;
    const selectedTestDefIds = selectedTestDefs.map(value => value.testDefinitionId);
    this.logger.debug(`=> selectedTestDef IDs: ${selectedTestDefIds}`, `${this.constructor.name}`);

    this.router.navigate(['/instructor-emailing/send-bulk-email'],
      { queryParams: { 'emailType': emailReminder, 'selectedTestDefIds': selectedTestDefIds.toString()}});
  }

  resetSearch() {
    this.searchByAssessmentDateCtrl.setValue('');
    // const selectedTestDefs = this.selection.selected;
    // const selectedTestDefIds = selectedTestDefs.map(value => value.testDefinitionId);
    // this.logger.debug(`=> selectedTestDef IDs: ${selectedTestDefIds}`, `${this.constructor.name}`);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected?.length;
    // console.log("=> numSelected: " + numSelected);

    const numRows = this.dataSource?.data.length;
    // const numRows = this.dataSource?.filteredData.length;
    // console.log("=> numRows: " + numRows);

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. Not used for now! */
  // toggleAllRows() {
  //   if (this.isAllSelected()) {
  //     this.selection.clear();
  //     return;
  //   }
  //
  //   this.selection.select(...this.dataSource.data);
  //   // this.selection.select(...this.dataSource.filteredData);
  // }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TestDefinition): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.testDefinitionId + 1}`;
  }

  // Open a modal
  _openModal(): NgbModalRef {
    return this.modalService.open(EmailLogModalComponent, {size: 'xl'});
  }

  // protected readonly element = element;
  protected readonly EmailReminder = EmailReminder;
  protected readonly Utils = InstructorEmailingUtils;
  protected readonly CommonUtils = CommonUtils;
}
