import {Component, Input} from '@angular/core';
import {AccommodationInstructions} from "../../model/types/accommodation-instructions";
import {CisSubmissionsUtils} from "../cis-submissions-utils";
import {ReviewStatusCode} from "../../common/enum/review-status-code.enum";
import {CommonUtils} from "../../common/utils/common-utils";

/**
 * CIS submissions general info.
 * Displays general CIS submissions info like:
 *    Review Status,
 *    Submission Status,
 *    Course, Section,
 *    Assessment Type,
 *    Number of Bookings,
 *    Test Definition ID,
 *    Accommodation Instruction ID,
 *    Clockwork Exam ID
 */
@Component({
  selector: 'app-cis-sub-gen-info',
  templateUrl: './cis-sub-gen-info.component.html',
  styleUrls: ['./cis-sub-gen-info.component.scss']
})
export class CisSubGenInfoComponent {

  @Input()
  cisSubmission: AccommodationInstructions;

  protected readonly ReviewStatusCode = ReviewStatusCode;
  protected readonly Utils = CisSubmissionsUtils;
  protected readonly CommonUtils = CommonUtils;
}
