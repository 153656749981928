import {WorkflowStatusCode} from "../enum/workflow-status-code.enum";
import {ReviewStatusCode} from "../enum/review-status-code.enum";
import {CisSubmissionStatus} from "../enum/cis-submission-status.enum";

export class CommonUtils {

  //** Check if all user entered tokens, in filter field, match the data field. */
  public static checkMatch(data: string, filter: string): boolean {
    if (!filter) return  true;

    const wordsArray: string[] = filter.split(' ');
    const textMatch: boolean = wordsArray.every((value) => {
      const matchExp: RegExp = new RegExp(value, 'i');
      return  matchExp.test(data);
    });

    return textMatch;
  }

  /** Based on the submission status code, determines the user-friendly status to be shown on dashboard. */
  public static determineCisSubmissionStatus(submissionStatus: string): string {
    if (!submissionStatus) {
      return 'N/A';
    }

    let status = '';
    if (submissionStatus === WorkflowStatusCode.NEED_FILES) {
      status = 'Step 3';
    } else if (submissionStatus === WorkflowStatusCode.SUBMITTED_TO_ATS) {
      status = 'Step 5';
    } else {
      status = 'N/A';
    }

    return status;
  }

  static reviewStatusCodeVals: ReviewStatusCode[] = [
    ReviewStatusCode.REVIEWED,
    ReviewStatusCode.REVIEWED_INSTRUCTOR_UPDATED,
    ReviewStatusCode.PENDING_CONFIRMATION,
    ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE,
    ReviewStatusCode.PENDING_CONFIRMATION_INSTRUCTOR_UPDATED,
    ReviewStatusCode.NOT_REVIEWED,
    ReviewStatusCode.NOT_APPLICABLE
  ];

  static reviewStatusCodeMap: Map<string, string> = new Map([
    [ReviewStatusCode.REVIEWED, 'Reviewed'],
    [ReviewStatusCode.REVIEWED_INSTRUCTOR_UPDATED, 'Reviewed - Instructor Updated'],
    [ReviewStatusCode.PENDING_CONFIRMATION, 'Pending Confirmation'],
    [ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE, 'Pending Confirmation - Awaiting File'],
    [ReviewStatusCode.PENDING_CONFIRMATION_INSTRUCTOR_UPDATED, 'Pending Confirmation - Instructor Updated'],
    [ReviewStatusCode.NOT_REVIEWED, 'Not Reviewed'],
    [ReviewStatusCode.NOT_APPLICABLE, 'N/A'],
  ])

  static cisSubmissionStatusVals: CisSubmissionStatus[] = [
    CisSubmissionStatus.STEP_3,
    CisSubmissionStatus.STEP_5,
    CisSubmissionStatus.NOT_SUBMITTED,
    CisSubmissionStatus.NOT_FOUND
  ]
  static cisSubmissionStatusMap: Map<CisSubmissionStatus, string> = new Map([
    [CisSubmissionStatus.STEP_3, 'Step 3'],
    [CisSubmissionStatus.STEP_5, 'Step 5'],
    [CisSubmissionStatus.NOT_SUBMITTED, 'Not Submitted'],
    [CisSubmissionStatus.NOT_FOUND, 'Not Exported to CIS']
  ]);

  static cisSubmissionStatusAsStrMap: Map<string, string> = new Map([
    [CisSubmissionStatus.STEP_3.toString(), 'Step 3'],
    [CisSubmissionStatus.STEP_5.toString(), 'Step 5'],
    [CisSubmissionStatus.NOT_SUBMITTED.toString(), 'Not Submitted'],
    [CisSubmissionStatus.NOT_FOUND.toString(), 'N/A']
  ]);

}
