export enum ReviewStatusCode {

  REVIEWED = 'REVIEWED',
  REVIEWED_INSTRUCTOR_UPDATED = 'REVIEWED_INSTRUCTOR_UPDATED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  PENDING_CONFIRMATION_INSTRUCTOR_UPDATED = 'PENDING_CONFIRMATION_INSTRUCTOR_UPDATED',
  PENDING_CONFIRMATION_AWAITING_FILE = 'PENDING_CONFIRMATION_AWAITING_FILE',
  NOT_REVIEWED = 'NOT_REVIEWED',
  NOT_APPLICABLE = 'NOT_APPLICABLE'
}
